@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

html, body {
    height: 100%;
    color: #3b3b3b;
}

@font-face {
    font-family: 'NewEdge';
    src: url("../public/fonts/NewEdge6666-LightRounded.otf");
}

* {
    border-color: black;
}

a:hover {
    color: #EF5FA7;
}

.h-screen {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}

.no-overscroll {
    touch-action: none;
    overscroll-behavior-y: none;
}


button:focus {
    outline: none;
}

button:disabled {
    opacity: 0.2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}